import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nonogram from '../components/Nonogram';
import OpponentGrid from '../components/OpponentGrid';
import './Room.scss';

type Grid = string[][];

const Room: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const [rowHints, setRowHints] = useState<number[][]>([]);
  const [columnHints, setColumnHints] = useState<number[][]>([]);
  const [grid, setGrid] = useState<Grid | null>(null);
  const [opponentGrids, setOpponentGrids] = useState<Map<number,Grid>>(new Map());
  const [socket, setSocket] = useState<WebSocket | null>(null);
  // used to force a re-render of the Nonogram component
  const gridChangeCount = useRef<number>(0);
  const [connectedUsers, setConnectedUsers] = useState<number>(0);
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'localhost:8010/';

  useEffect(() => {
    console.log('Connecting to room:', code);
    const socket = new WebSocket('wss://' + apiEndpoint + 'join/' + code);
    setSocket(socket);
    
    // WebSocket event listeners
    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    socket.onerror = (_error) => {
      alert('Could not connect to room.')
      window.location.href = '/';
    };

    // Clean up the WebSocket connection
    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        console.log('Received message:', event.data);
        if (event.data.startsWith('n')) {
          // Receiving nonogram data

          let lines = event.data.split('\n');
          const width = parseInt(lines[0].split(' ')[1]);
          const height = parseInt(lines[0].split(' ')[2]);
          const rowHints = lines.slice(1, height + 1).map((line: string) => {
            if (line === '') {
              return [];
            } else {
              return line.split(' ').map((hint) => parseInt(hint));
            }
          });
          const columnHints = lines.slice(height + 1, height + width + 1).map((line: string) => {
            if (line === '') {
              return [];
            } else {
              return line.split(' ').map((hint) => parseInt(hint));
            }
          });
          const initialState = lines.slice(height + width + 1, lines.length - 1).map((line: string) => line.split(' '));
          setRowHints(rowHints);
          setColumnHints(columnHints);
          setGrid(initialState);
        } else if (event.data.startsWith('f')) {
          // Receiving a cell fill update

          const lines = event.data.split('\n');
          const rowIndex = parseInt(lines[0].split(' ')[1]);
          const columnIndex = parseInt(lines[0].split(' ')[2]);
          const fill = lines[0].split(' ')[3];
          if (grid) {
            const newGrid = [...grid];
            newGrid[columnIndex][rowIndex] = fill;
            setGrid(newGrid);
            gridChangeCount.current++;
          }
        } else if (event.data.startsWith('c')) {
          // Update connected users

          const parts = event.data.split(' ');
          setConnectedUsers(parseInt(parts[1]));
        } else if (event.data.startsWith('g')) {
          // Receiving opponent grid

          const lines = event.data.split('\n');
          const id = parseInt(lines[0].split(' ')[1]);
          const opponentGrid = lines.slice(1, lines.length).map((line: string) => line.split(' '));
          setOpponentGrids((prev) => {
            const newMap = new Map(prev);
            newMap.set(id, opponentGrid);
            return newMap;
          });
        } else if (event.data.startsWith('o')) {
          // Receiving opponent action

          const parts = event.data.split(' ');
          const id = parseInt(parts[1]);
          const rowIndex = parseInt(parts[2]);
          const columnIndex = parseInt(parts[3]);
          const fill = parts[4] === 't' ? 'f' : 'u';

          setOpponentGrids((prev) => {
            const newMap = new Map(prev);
            const opponentGrid = prev.get(id);
            if (opponentGrid) {
              const newGrid: string[][] = [...opponentGrid];
              newGrid[columnIndex][rowIndex] = fill;
              newMap.set(id, newGrid);
            } else {
              // create new grid
              let newGrid: string[][] = [];
              if (!grid) {
                return newMap;
              }
              const width = grid.length;
              const height = grid[0].length;
              for (let i = 0; i < width; i++) {
                newGrid.push([]);
                for (let j = 0; j < height; j++) {
                  newGrid[i].push('u');
                }
              }
              newGrid[columnIndex][rowIndex] = fill;
              newMap.set(id, newGrid);
            }
            return newMap;
          });
        }
      };
    }
  }, [grid, socket, connectedUsers]);

  const handleCellClick = (rowIndex: number, columnIndex: number, fill: boolean) => {
    if (socket && grid) {
      let value = fill ? 'f' : 'e';
      if (grid[rowIndex][columnIndex] === value) {
        value = 'u';
      }
      // console.log(grid[columnIndex][rowIndex], value);
      socket.send(`f ${columnIndex} ${rowIndex} ${value}`);
    }
  };

  return (
    <div className="room">
      <h1>Room: {code}</h1>
      {grid && <Nonogram
        key={gridChangeCount.current}
        rowHints={rowHints}
        columnHints={columnHints}
        grid={grid}
        sendCellClick={handleCellClick} 
      />}
      <p className='connected-users'>Connected users: {connectedUsers}</p>
      {Array.from(opponentGrids.entries()).map(([id, grid]) => (
        <OpponentGrid key={id} grid={grid} />
      ))}
    </div>
  );
};

export default Room;
