import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.scss';

const Landing: React.FC = () => {
  const [width, setWidth] = React.useState(10);
  const [height, setHeight] = React.useState(10);
  const [gamemode, setGamemode] = React.useState('coop');

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'localhost:8010/';
  const navigate = useNavigate();

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (width > 50 || height > 50 || width < 2 || height < 2) {
      alert('Width and height must be between 2 and 50');
      return;
    }

    const formdata = new URLSearchParams();
    formdata.append('width', width.toString());
    formdata.append('height', height.toString());
    formdata.append('gamemode', gamemode);

    fetch('https://' + apiEndpoint + 'create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formdata.toString(),
    })
    .then(res => {
      if (res.status !== 200) {
        throw new Error('Failed to create room');
      }
      return res.text();
    })
    .then(data => {
      console.log(data);
      navigate('/room/' + data);
    })
    .catch(err => {
      alert(err);
    });
  };

  return (
    <div className='landing-wrapper'>
      <div className='landing'>
        <h1>Nonograms Together</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Width:
            <input type='number' value={width} onChange={e => e.target.value && setWidth(parseInt(e.target.value))} />
          </label>
          <br />
          <label>
            Height:
            <input type='number' value={height} onChange={e => e.target.value && setHeight(parseInt(e.target.value))} />
          </label>
          <br />
          <label>
            Gamemode:
            <select onChange={e => e.target.value && setGamemode(e.target.value)}>
              <option value='coop'>Cooperative</option>
              <option value='comp'>Competitive</option>
            </select>
          </label>
          <br />
          <input type='submit' value='Create Room' />
        </form>
      </div>
    </div>
  );
};

export default Landing;

