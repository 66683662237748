import React, { useState } from 'react';
import './Nonogram.scss';

export interface NonogramProps {
  rowHints: number[][];
  columnHints: number[][];
  grid: string[][];
  sendCellClick: (rowIndex: number, columnIndex: number, fill: boolean) => void;
}

const Nonogram: React.FC<NonogramProps> = ({
  rowHints,
  columnHints,
  grid,
  sendCellClick,
}) => {
  const [startCell, setStartCell] = useState<{rowIndex: number, columnIndex: number} | null>(null);
  const [endCell, setEndCell] = useState<{rowIndex: number, columnIndex: number} | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const isComplete = (hints: number[], cells: string[]) => {
    let count = 0;
    let hintsIndex = 0;
    for (let i = 0; i < cells.length; i++) {
      if (cells[i] === 'f') {
        count++;
      } else if (cells[i] === 'e') {
        if (count === 0) {
          continue;
        }
        if (count === hints[hintsIndex]) {
          hintsIndex++;
        } else {
          return false;
        }
        count = 0;
      } else {
        return false;
      }
    }
    if (count > 0) {
      if (count === hints[hintsIndex]) {
        hintsIndex++;
      } else {
        return false;
      }
    }
    return hints.length === hintsIndex;
  }

  const isColumnComplete = (columnIndex: number) => {
    const column = grid.map(row => row[columnIndex]);
    return isComplete(columnHints[columnIndex], column);
  }

  const isRowComplete = (rowIndex: number) => {
    const row = grid[rowIndex];
    return isComplete(rowHints[rowIndex], row);
  }

  const handleCellClick = (rowIndex: number, columnIndex: number) => {
    if (isDragging) {
      setEndCell({rowIndex, columnIndex});
    } else {
      setStartCell({rowIndex, columnIndex});
      setEndCell({rowIndex, columnIndex});
    }
  };

  const handleMouseDown = (rowIndex: number, columnIndex: number) => {
    setIsDragging(true);
    setStartCell({rowIndex, columnIndex});
    setEndCell({rowIndex, columnIndex});
  }

  const handleMouseUp = () => {
    if (!startCell || !endCell) return false;
    setIsDragging(false);
    const { rowIndex: startRow, columnIndex: startColumn } = startCell;
    const { rowIndex: endRow, columnIndex: endColumn } = endCell;

    const rowSpan = Math.abs(endRow - startRow);
    const columnSpan = Math.abs(endColumn - startColumn);
    const shortestAxis = rowSpan < columnSpan ? 'row' : 'column';

    if (shortestAxis === 'row') {
      for (let i = Math.min(startColumn, endColumn); i <= Math.max(startColumn, endColumn); i++) {
        console.log(i, startColumn);
        sendCellClick(startRow, i, true);
      }
    } else {
      for (let i = Math.min(startRow, endRow); i <= Math.max(startRow, endRow); i++) {
        console.log(i, startColumn);
        sendCellClick(i, startColumn, true);
      }
    }
    setStartCell(null);
    setEndCell(null);
  }

  const handleMouseMove = (rowIndex: number, columnIndex: number) => {
    if (isDragging) {
      setEndCell({rowIndex, columnIndex});
    }
  }

  const isSelected = (rowIndex: number, columnIndex: number) => {
    if (!startCell || !endCell) return false;
    const { rowIndex: startRow, columnIndex: startColumn } = startCell;
    const { rowIndex: endRow, columnIndex: endColumn } = endCell;

    const rowSpan = Math.abs(endRow - startRow);
    const columnSpan = Math.abs(endColumn - startColumn);
    const shortestAxis = rowSpan < columnSpan ? 'row' : 'column';

    if (shortestAxis === 'row') {
      return rowIndex === startRow && columnIndex >= Math.min(startColumn, endColumn) && columnIndex <= Math.max(startColumn, endColumn);
    } else {
      return columnIndex === startColumn && rowIndex >= Math.min(startRow, endRow) && rowIndex <= Math.max(startRow, endRow);
    }
  };

  const renderGrid = () => {
    return (
      <table className='nonogram-grid'>
        <tbody>
          <tr>
            {columnHints.map((hints, index) => (
              <td key={index} className={`column-hints ${(index+1) % 5 === 0 ? 'nonogram-col-thick' : ''} ${isColumnComplete(index) ? 'col-complete' : ''}`}>{hints.join('\n')}</td>
            ))}
          </tr>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, columnIndex) => (
                <td
                key={columnIndex}
                className={
                  `nonogram-cell ${cell} ` +
                  `${(columnIndex+1) % 5 === 0 ? 'nonogram-col-thick' : ''} ` +
                  `${(rowIndex+1) % 5 === 0 ? 'nonogram-row-thick' : ''} ` +
                  `${isSelected(rowIndex, columnIndex) ? 'selected' : ''} `
                }
                onClick={() => handleCellClick(rowIndex, columnIndex)}
                onContextMenu={(event) => {
                  event.preventDefault();
                  sendCellClick(rowIndex, columnIndex, false);
                }}
                onMouseDown={() => handleMouseDown(rowIndex, columnIndex)}
                onMouseUp={handleMouseUp}
                onMouseMove={() => handleMouseMove(rowIndex, columnIndex)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderRowHints = () => {
    return (
      <div className='row-hints-container'>
        {rowHints.map((hints, index) => (
          <div key={index} className={`row-hints ${(index+1) % 5 === 0 ? 'nonogram-row-thick' : ''} ${isRowComplete(index) ? 'row-complete' : ''}`}>{hints.join(' ')}</div>
        ))}
      </div>
    );
  };

  return (
    <div className='nonogram'>
      <div className='nonogram-grid-container'>
        {renderRowHints()}
        {renderGrid()}
      </div>
    </div>
  );
};

export default Nonogram;
