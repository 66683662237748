import './OpponentGrid.scss';

const OpponentGrid: React.FC<{ grid: string[][] }> = ({ grid }) => {
    return (
        <div className='opponent-grid-wrapper'>
            <table className='opponent-grid'>
            <tbody>
                {grid.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map((cell, columnIndex) => (
                    <td key={columnIndex} className={cell !== 'u' ? 'filled' : 'empty'}></td>
                    ))}
                </tr>
                ))}
            </tbody>
            </table>
        </div>
    );
};

export default OpponentGrid;
